import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vasodilators are drugs which are useful in the management of hypertension, angina, heart failure, MI, preeclampsia, hypertensive emergencies etc.`}</p>
    <p>{`The different classes of vasodilators used in current clinical practice has different actions on the coronary arteries and peripheral vasculature on both arteries and veins. Vasodilators more commonly affect the arteries but some vasodilators such as nitro-glycerine can affect the venous system predominantly.`}</p>
    <p><strong parentName="p">{`Table 1: Vasodilators and uses.`}</strong>{` `}<a parentName="p" {...{
        "href": "/volume-5/issue-1/vasodilators-in-clinical-day-to-day-practice-table1/"
      }}>{`View in new window`}</a></p>
    <div style={{
      "overflow": "scroll"
    }}>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Drugs`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Mechanism Of Action`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Adverse Effects`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Contraindications`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Uses`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Directly acting vasodilators`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Venous`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Nitrates (GTN and Nitroglycerin)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Releases NO through cyclic GMP`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tachycardia, headache, flushing and orthostatic hypotension`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`As they decrease preload do not use in inferior MI or right ventricular infarction`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Acute and chronic angina`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Arterial`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`CCB (DHP like Amlodipine and non-DHP like Verapamil)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Blocks calcium channels in the cardiac and smooth muscle`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Gingival hyperplasia and peripheral edema, AV block, bradycardia and constipation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hypotension and hypersensitivity`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`HTN, arrythmia, angina`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Minoxidil, Diazoxide`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`ATP sensitive potassium channel openers`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hypertrichosis, Fluid retention`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Alopecia`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Prazosin`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Alpha Blocker`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`First dose hypotension`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`CHF`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3rd line drug in HTN`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hydralazine`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Directly relaxes arteriolar smooth muscles through cyclic AMP`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tachycardia, fluid retention and SLE`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`CAD, angina, RHD as it causes compensatory tacchycardia`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`PIH`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Mixed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`ACE inhibitors`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Prevent conversion of Angiotensin I to II`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dry cough hyperkalemia and hypotension`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pregnancy and hereditary angioedema`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Heart failure, HTN, MI, CCF, Scleroderma crisis`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`ARB`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Prevents Angiotensin II from binding to its receptor (cardio-reno protective)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hyperkalemia and hypotension`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pregnancy`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sodium Nitroprusside`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Both venous and arteriolar dilator releasing NO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Cyanide toxicity`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hypertensive emergencies`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Beta 2 receptor agonist`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Salbutamol Terbutaline`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Beta 2 mediated vasodilatation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tremors hypokalemia`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Centrally acting Alpha 2 receptor agonist`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Clonidine, Methyldopa`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Acts on presynaptic alpha 2 receptor and decreases sympathetic outflow`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Rebound hypertension on withdrawal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Clonidine - Last Drug Of Choice In HTN`}<br />{`Methyldopa - First drug of choice in PIH but nowadays we are into “Nifedipine Retard or Labetelol”`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Endothelin receptor antagonists`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bosentan, Ambrisentan`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`ETA and ETB receptor blocker`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Anaemia, teratogenecity`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pulmonary arterial hypertension`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Beta Blockers with Nitric Oxide vasodilatation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bisoprolol, Nebivolol`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Beta 1 block reduces heart rate and cardiac work load and NO causes vasodilatation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bradycardia`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Heart Block`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Young hypertensives`}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>{`Clinical Pearls`}</h3>
    <ol>
      <li parentName="ol">{`Educate the patient about adverse effects`}</li>
      <li parentName="ol">{`Importance of taking their vasodilator medication as prescribed`}</li>
      <li parentName="ol">{`Under treatment or non compliance can cause severe hypertension and complications which are preventable`}</li>
      <li parentName="ol">{`Ask the patient to inform if they have missed or want to stop the treatment`}</li>
      <li parentName="ol">{`Educate LSM, plant based eating habits, 10,000 steps per day aerobic excercises, and avoid smoking, alcohol and recreational drugs`}</li>
      <li parentName="ol">{`Clinical pharmacologist should assist in selection, dosing, medication
reconciliation and patient education`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      